import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import './assets/css/custom.css';
import './assets/css/global.css';
import './layout/pages/dashboard/style.css';
import './layout/pages/signIn/style.css';
import { router } from './routes/index';
import { ThemeProvider } from './theme/index';
import Loader from './layout/loader';
import { useLoader } from './recoil';
const container = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(container);

const AppCover = () => {
  const isLoading = useLoader();

  return (
    <>
      {isLoading && <Loader />}
      <ThemeProvider>
        <SnackbarProvider>
          <CssBaseline />
          <RouterProvider router={router} />
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
};
// Render the top-level React component
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <AppCover />
    </RecoilRoot>
  </React.StrictMode>
);
