export const Config = {
  STRIPE_CARD_TOKEN: 'https://api.stripe.com/v1/tokens',
  END_POINTS: {
    LOGIN: 'login',
    DASHBOARD_COUNT: 'restaurant/web/dashboard',
    GET_TRANSACTIONS: 'restaurant/payment/list?page=',
    GET_CURRENT_CARDS_DETAILS: 'restaurant/card/list',
    JOB_LISTING: 'restaurant/jobs?page=',
    CANCEL_SUBSCRIPTION: 'restaurant/cancel/subscription',
    GET_STAFF: 'restaurant/staff?page=',
    ADD_STAFF_MEMBER: 'restaurant/staff',
    DELETE_STAFF_MEMBER: 'restaurant/staff/delete/',
    UPDATE_STAFF_MEMBER: 'restaurant/staff/update',
    INVITE_STAFF_MEMBER: 'restaurant/invite/staff/member',
    GET_EQUIPMENTS: 'restaurant/equipment?page=',
    GET_PROFILE_DETAIL: 'restaurant/profile',
    ADD_EQUIPMENT: 'restaurant/equipment',
    FORGOT_PASSWORD: 'forgot-password',
    UPLOAD_FILE: 'file-upload',
    VERIFY_OTP: 'verify-otp',
    CREATE_NEW_PASSWORD: 'create-new-password',
    GET_INVOICE_DETAIL: 'restaurant/invoice/',
    REPLACE_CARD: 'restaurant/update/card',
    PLAN_SUBSCRIPTION: 'restaurant/plan/subscription',
  },
};
