import { atom, selector, useRecoilValue } from 'recoil';

interface UserData {
  userName: string;
}

const initialState: UserData = {
  userName: '',
};

export const UserDetail = atom<UserData>({
  key: 'userDetail',
  default: initialState,
});

export const userDataSelector = selector({
  key: 'userDataSelector',
  get: ({ get }) => get(UserDetail),
  set: ({ set }, newValue) => set(UserDetail, newValue),
});

export function useUserData() {
  const selected = useRecoilValue(UserDetail);
  return selected;
}
