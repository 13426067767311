export const setLocalStorage = (key: string, obj: string) => {
  localStorage.setItem(key, obj);
  return true;
};

export const getLocalStorage = (key: string) => {
  const value = localStorage.getItem(key);
  return value ? value : null;
};

export const deleteLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
  deleteLocalStorage('access_token');
  deleteLocalStorage('refresh_token');
};
