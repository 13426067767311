import React, { FunctionComponent, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import '../modal/modal.css';

type MenuItemsProps = {
  options: string[];
  onItemClick: (selectedOption: string) => void;
};

const ITEM_HEIGHT = 48;

const LongMenu: FunctionComponent<MenuItemsProps> = ({
  options = [],
  onItemClick,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open}
        aria-haspopup="true"
        onClick={handleClick}
        style={{ color: '#b1b1b1' }}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
        sx={{
          zIndex: { xs: 1300, md: 'unset' },
        }}
        disablePortal
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            style={{ fontFamily: 'Avenir' }}
            selected={option === 'Pyxis'}
            onClick={() => {
              onItemClick(option);
              handleClose();
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default LongMenu;
