import {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
  createContext,
} from 'react';
import { useMediaQuery } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IMAGES, RoutesNames, SVG } from '../../common/utils';
import './menu-sidebar-links.css';
import LongMenu from '../../common/popupMenu';
import {
  deleteLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from '../../common/storage';
import { getUserDetail } from '../../common/webServices/user';
import { Config } from '../../common/config';
import { useRecoilState } from 'recoil';
import { userDataSelector } from '../../recoil/userData';

const MenuSidebarLinks: FunctionComponent = () => {
  const navigate = useNavigate();
  const [, setUserName] = useRecoilState(userDataSelector);

  const location = useLocation();
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const [userDetail, setUserDetail] = useState<any>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target as Node)
    ) {
      setIsSidebarOpen(false);
    }
  };

  const handleLog = (selectedOption: string) => {
    deleteLocalStorage('apiToken');
    navigate('/');
  };
  const [resolution, setResolution] = useState({ width: 0, height: 0 });

  useEffect(() => {
    function handleResize() {
      setResolution({ width: window.innerWidth, height: window.innerHeight });
    }

    // Attach the event listener when the component mounts
    window.addEventListener('resize', handleResize);

    // Call the handleResize function immediately to get the initial resolution
    handleResize();

    // Detach the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) {
      document.addEventListener('mousedown', handleClickOutside);
      setIsSidebarOpen(false);
    }
    return () => {
      if (isMobile) {
        document.removeEventListener('mousedown', handleClickOutside);
        setIsSidebarOpen(true);
      }
    };
  }, [isMobile]);

  useEffect(() => {
    getProfile();
  }, []);
  async function getProfile() {
    const apiResponse: any = await getUserDetail(
      Config.END_POINTS.GET_PROFILE_DETAIL
    );
    const { status, data } = apiResponse;
    if (status) {
      if (apiResponse?.status_code == 200) {
        setUserDetail(data);
        setUserName({ userName: data?.display_name });
      }
    } else {
      if (apiResponse?.status_code == 400) {
      }
    }
  }

  const handleButtonClick = () => {
    if (resolution.width <= 1200) {
      setIsSidebarOpen(!isSidebarOpen);
    }
  };

  return (
    <>
      {isMobile && (
        <button className="custom-ham" onClick={handleButtonClick}>
          <MenuIcon />
        </button>
      )}

      <div className="sidebar-w100">
        {isSidebarOpen && (
          <div ref={sidebarRef} className="sidebar_main">
            <div className="menu-sidebar">
              <Link className="logo" to="/">
                <img
                  className="vector-icon5"
                  alt=""
                  src={IMAGES.dashboard.appLogo}
                />
              </Link>
              <div className="links">
                {RoutesNames.map((item: any, index: any) => {
                  return (
                    <Link
                      key={index}
                      className={
                        location.pathname === item.routeName
                          ? ' link-01  selected'
                          : 'link-01 '
                      }
                      to={item?.routeName}
                    >
                      <div className="name-parent" onClick={handleButtonClick}>
                        <div className="name">{item?.name}</div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className="side-footer">
              <img
                className="icon-left-copy-9"
                alt=""
                src={
                  userDetail
                    ? process.env.REACT_APP_STORAGE_BASE_URL +
                      userDetail?.company?.file?.name
                    : IMAGES.dashboard.avatar
                }
              />
              <h5 className="bruce-lee">{userDetail?.display_name}</h5>
              <h6 className="guru-member">{userDetail?.email}</h6>
              <LongMenu
                //  styleColor={{color:"#b1b1b1"}}
                options={['Logout']}
                onItemClick={(i) => handleLog(i)}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MenuSidebarLinks;
