import userPath from '../../assets/images/avatar.png';
import avatarPath from '../../assets/images/icon-left-copy-9@2x.png';
import appLogoPath from '../../assets/images/website-logo.png';
import constructionWorkerPath from '../../assets/svg/constructionworker-1-1.svg';
import messagePath from '../../assets/svg/message.svg';
import shapePath from '../../assets/svg/shape.svg';
import starPath from '../../assets/svg/vector2.svg';
import suitcasePath from '../../assets/svg/suitcase-1-1.svg';
import suitcaseTwoPath from '../../assets/svg/suitcase-1-11.svg';
import signIn from '../../assets/svg/vector.svg';
import SearchLogo from '../../assets/svg/search-icon.svg';
import filter from '../../assets/svg/filter.svg';
import cross from '../../assets/svg/cross.svg';
import avatar from '../../assets/images/avatar.png';
import eyeIcon from '../../assets/svg/eye.svg';
import rightArrow from '../../assets/svg/right.svg';
import visa from '../../assets/svg/visa-img.svg';
import tools from '../../assets/svg/pic.svg';
import equipment1 from '../../assets/images/img@2x.png';
import message from '../../assets/svg/message.svg';
import vectorMessage from '../../assets/svg/vector1.svg';
import logoPath from '../../assets/svg/vector.svg';
import checkSvg from '../../assets/svg/check.svg';
import userIcon from '../../assets/svg/no-profile-picture-15257.svg';
import vaccumIcon from '../../assets/images/Rectangle 1080.png';
import cloudSvg from '../../assets/svg/upload-cloud.svg';
import congrats from '../../assets/svg/congrats.svg';

const SVG = {
  common: {
    logo: logoPath,
    congrats: congrats,
  },
  dashboard: {
    suitCase: suitcasePath,
    suitCase2: suitcaseTwoPath,
    constructionWorker: constructionWorkerPath,
    message: messagePath,
    star: starPath,
    shape: shapePath,
    signInLogo: signIn,
    searchLogo: SearchLogo,
  },
  paymentHistory: {
    filter: filter,
    cross: cross,
    eyeIcon: eyeIcon,
    arrow: rightArrow,
    visa: visa,
  },
  jobsModal: {
    tools: tools,
    messageIcon: vectorMessage,
  },
  subscription: {
    check: checkSvg,
  },
  jobs: {
    user: userIcon,
  },
  addEquipment: {
    cloud: cloudSvg,
  },
};
const IMAGES = {
  dashboard: {
    userAvatar: userPath,
    appLogo: appLogoPath,
    avatar: avatarPath,
  },
  paymentHistory: {
    avatar: avatar,
  },
  jobs: {
    equipment: equipment1,
  },
  addEquipment: {
    vaccum: vaccumIcon,
  },
};

const RoutesNames = [
  {
    name: 'Dashboard',
    routeName: '/dashboard',
  },
  {
    name: 'Manage Jobs',
    routeName: '/jobs',
  },
  {
    name: 'Manage Staff',
    routeName: '/manageStaff',
  },
  {
    name: 'Payments History',
    routeName: '/paymentHistory',
  },
  {
    name: 'Equipment List',
    routeName: '/equipmentList',
  },
  {
    name: 'Subscription Details',
    routeName: '/subscriptionDetails',
  },
];

export { SVG, IMAGES, RoutesNames };
