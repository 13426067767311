import { atom, selector, useRecoilCallback, useRecoilValue } from 'recoil';

export const IsLoader = atom<boolean>({
  key: 'isLoading',
  default: false,
});

export const loaderSelector = selector({
  key: 'loaderSelector',
  get: ({ get }) => get(IsLoader),
  set: ({ set }, newValue) => set(IsLoader, newValue),
});

export function useLoader() {
  const selected = useRecoilValue(IsLoader);
  return selected;
}
