import { GlobalStyles } from '@mui/material';
import * as React from 'react';
import { Outlet } from 'react-router-dom';

/**
 * The minimal app layout to be used on pages such Login/Signup,
 * Privacy Policy, Terms of Use, etc.
 */
export function AppLayout(): JSX.Element {
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{
          '#root': {
            display: 'flex',
            minHeight: '100vh',
          },
        }}
      />
      <React.Suspense>
        <Outlet />
      </React.Suspense>
    </React.Fragment>
  );
}
