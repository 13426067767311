import React, { lazy } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import { RootError } from '../layout/RootError';
import { getLocalStorage } from '../common/storage';
import MenuSidebarLinks from '../components/menuSideBar';
import { AppLayout } from '../layout/AppLayout';

const Dashboard1 = lazy(() => import('../layout/pages/dashboard'));
const Jobs = lazy(() => import('../layout/pages/jobs'));
const ManageStaff = lazy(() => import('../layout/pages/manageStaff'));
const PaymentHistory = lazy(() => import('../layout/pages/payments'));
const Dashboard2 = lazy(() => import('../components/dashboard2/dashboard2'));
const SignIn = lazy(() => import('../layout/pages/signIn'));
const Equipment = lazy(() => import('../layout/pages/equipmentManagement'));
const Subscription = lazy(() => import('../layout/pages/subscription'));
const ForgotPassword = lazy(
  () => import('../layout/pages/forgotPassword/forgotPassword')
);
const ResetPassword = lazy(
  () => import('../layout/pages/resetPassword/resetPassword')
);
const SubscriptionSuccess = lazy(() => import('../components/successPage'));
/**
 * Application routes
 * https://reactrouter.com/en/main/routers/create-browser-router
 */
export const ProtectedRoute = (props: any) => {
  const { children } = props;
  const isToken = getLocalStorage('apiToken');
  if (!isToken) {
    return <Navigate to="/" replace></Navigate>;
  }
  return children;
};
export const PublicRoutes = (props: any) => {
  const { children } = props;
  const isToken = getLocalStorage('apiToken');
  if (isToken) {
    return (
      <>
        <Navigate to="/dashboard" replace></Navigate>
      </>
    );
  }
  return children;
};
export const router = createBrowserRouter([
  {
    path: '',
    element: <AppLayout />,
    errorElement: <RootError />,
    children: [
      {
        path: '',
        element: (
          <PublicRoutes>
            <SignIn />
          </PublicRoutes>
        ),
      },
      {
        path: '/forgotPassword',
        element: (
          <PublicRoutes>
            <ForgotPassword />
          </PublicRoutes>
        ),
      },
      {
        path: '/resetPassword',
        element: (
          <PublicRoutes>
            <ResetPassword />
          </PublicRoutes>
        ),
      },

      {
        path: '',
        element: (
          <ProtectedRoute>
            <MenuSidebarLinks />
            <AppLayout />
          </ProtectedRoute>
        ),
        children: [
          { path: '/dashboard', element: <Dashboard1 /> },
          { path: '/jobs', element: <Jobs /> },
          { path: '/manageStaff', element: <ManageStaff /> },
          { path: '/paymentHistory', element: <PaymentHistory /> },
          { path: '/dashboard-2', element: <Dashboard2 /> },
          { path: '/equipmentList', element: <Equipment /> },
          { path: '/subscriptionDetails', element: <Subscription /> },
          { path: '/subscriptionSuccess', element: <SubscriptionSuccess /> },
        ],
      },
    ],
  },
]);

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}
