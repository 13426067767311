import axios from 'axios';
import { getLocalStorage } from '../storage';
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config: any) => {
    const token = getLocalStorage('apiToken') && getLocalStorage('apiToken');
    config.baseURL = process.env.REACT_APP_BASE_URL;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);
// let isRefreshing = false;
// let failedQueue = [];

// const processQueue = (error, token = null) => {
//   failedQueue.forEach(prom => {
//     if (error) {
//       prom.reject(error);
//     } else {
//       prom.resolve(token);
//     }
//   });

//   failedQueue = [];
// };

axiosInstance.interceptors.response.use(
  async (response: any) => {
    return response?.data;
  },
  async function (error: any) {
    // const originalRequest = error.config;
    // if (error.response.status === 401 && !originalRequest._retry) {
    //   if (isRefreshing) {
    //     return new Promise(function (resolve, reject) {
    //       failedQueue.push({ resolve, reject });
    //     })
    //       .then(token => {
    //         originalRequest.headers['Authorization'] = 'Bearer ' + token;
    //         return axios(originalRequest);
    //       })
    //       .catch(err => {
    //         return Promise.reject(err);
    //       });
    //   }
    //   originalRequest._retry = true;
    //   isRefreshing = true;
    //   const refresh_token = getLocalStorage('refresh_token')
    //   return new Promise(function (resolve, reject) {
    //     axiosInstance.post('refresh-token', { refresh_token }).then((response) => {
    //       const access_token = response.data.data.access_token
    //       const refresh_token = response.data.data.refresh_token
    //       axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
    //       originalRequest.headers['Authorization'] = 'Bearer ' + access_token
    //       setLocalStorage('access_token', access_token)
    //       setLocalStorage('refresh_token', refresh_token)
    //       processQueue(null, access_token);
    //       resolve(axios(originalRequest));
    //     })
    //       .catch(err => {
    //         const cookieData = GetCookie('re_used')
    //         if (cookieData) {
    //           const userDetails = Decrypt(cookieData)
    //           const data = userDetails[0]
    //           return new Promise(function (resolve, reject) {
    //             axiosInstance.post('sign-in', data).then((response) => {
    //               const access_token = response.data.data.access_token
    //               const refresh_token = response.data.data.refresh_token
    //               axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
    //               originalRequest.headers['Authorization'] = 'Bearer ' + access_token
    //               setLocalStorage('access_token', access_token)
    //               setLocalStorage('refresh_token', refresh_token)
    //               processQueue(null, access_token);
    //               resolve(axios(originalRequest));
    //             })
    //           })
    //         }
    //         else {
    //           processQueue(err, null);
    //           const loginData = JSON.parse(getLocalStorage("persist:root"))?.login;
    //           const loginDataParsed = JSON.parse(loginData);
    //           store.dispatch(loginState({...loginDataParsed, ...{ loggedIn: false, role: null, name: null }}));
    //           store.dispatch(inviteEmail({email: null,
    //             role:null,
    //             production_name:null,
    //             studio_name:null,
    //             id:null,
    //             prod_id:null,
    //             production_id:null,
    //             redirectURL:null}))
    //           clearLocalStorage()
    //           // DeleteCookie('re_used')
    //           reject(err);
    //         }
    //       })
    //       .then(() => {
    //         isRefreshing = false;
    //       });
    //   });
    // }

    return Promise.reject(error);
  }
);
export { axiosInstance };
